import type {
  StoreQuery,
  ItemsStoreQuery,
  ItemStoreSetupOptions,
  SimpleStoreSetupOptions,
  ItemsStoreSetupOptions
} from './types'

export const createQuery = <
  KV extends string | number = string | number,
  K extends string = string
>(
  options:
    | ItemStoreSetupOptions<KV>
    | SimpleStoreSetupOptions
    | ItemsStoreSetupOptions<K>,
  query: StoreQuery | ItemsStoreQuery
) => {
  const result: {
    fields?: string
    expand?: string
    page?: number
    'per-page'?: number
    limit?: number
    [key: string]: any
  } = {}

  for (const key in query) {
    if (key === 'fields') {
      if (query.fields) {
        result.fields = query.fields.join(',')
      }
    } else if (key === 'expand') {
      if (query.expand) {
        result.expand = query.expand.join(',')
      }
    } else if (key === 'page') {
      if (
        (options as ItemsStoreSetupOptions).pagination &&
        (options as ItemsStoreSetupOptions).paginationType === 'page' &&
        (query as ItemsStoreQuery).page &&
        (query as ItemsStoreQuery).page > 1
      ) {
        result.page = (query as ItemsStoreQuery).page
      }
    } else if (key === 'perPage') {
      if ((options as ItemsStoreSetupOptions).pagination) {
        if ((options as ItemsStoreSetupOptions).paginationType === 'page') {
          result['per-page'] = (query as ItemsStoreQuery).perPage
        } else {
          result.limit = (query as ItemsStoreQuery).perPage
        }
      }
    } else if ((query as ItemsStoreQuery)[key]) {
      result[key] = (query as ItemsStoreQuery)[key]
    }
  }

  return result
}

type CreateUrlOverload = {
  (path: SimpleStoreSetupOptions['path']): string

  <KV extends string | number = string | number, K extends string = string>(
    path: ItemStoreSetupOptions<KV>['path'] | ItemsStoreSetupOptions<K>['path'],
    query: KV | ItemsStoreQuery,
    options: Record<string, any>
  ): string
}

export const createUrl: CreateUrlOverload = (
  path: string | ((...args: any[]) => string),
  query?: string | number | ItemsStoreQuery,
  options?: Record<string, any>
) => (typeof path === 'function' ? path(query, options) : path)
