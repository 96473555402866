import type {
  StoreOptions,
  StoreQuery,
  ItemStoreOptions,
  SimpleStoreSetupOptions,
  ItemsStoreQuery,
  ItemsStoreSetupOptions
} from './types'

export const defaultStoreOptions = {
  cache: false
} satisfies StoreOptions

export const defaultItemStoreQuery = {} satisfies Partial<StoreQuery>

export const defaultItemStoreOptions = {
  query: null
} satisfies Partial<ItemStoreOptions>

export const defaultSimpleStoreOptions =
  {} satisfies Partial<SimpleStoreSetupOptions>

export const defaultItemsStoreQuery = {
  perPage: 20
} satisfies Partial<ItemsStoreQuery>

export const defaultItemsStoreOptions = {
  path: '/',
  query: null,
  pagination: true,
  paginationType: 'page'
} satisfies Partial<ItemsStoreSetupOptions>
